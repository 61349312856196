<template>
  <div class="container">
    <div class="btn" @click="handleToDownload"></div>
  </div>
</template>

<script>
export default {
  methods: {
    /** 下载App */
    handleToDownload() {
      const userAgent = navigator.userAgent;
      const isIOS = /iPhone|iPad|iPod/.test(userAgent);
      const isAndroid = /Android/.test(userAgent);
      if (isIOS) {
        location.href = "https://apps.apple.com/cn/app/%E7%B2%97%E9%97%A8/id1593823623";
      } else if (isAndroid) {
        location.href = "https://assets.cumen.fun/apk/cumen-arm64-v8a-release.apk";
      } else {
        this.$message.warning("请用移动端设备访问");
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
$multipleCount: 1;

body {
  width: 100%;
  height: 100vh;
  background-color: #c7fbbe;
}

.container {
  width: 100%;
  height: 173.066vw;
  background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018ca5aa-432a-bf22-9584-e84a971ce5c4?x-oss-process=style/origin") no-repeat center center / cover;

  > .btn {
    position: fixed;
    left: 0;
    bottom: px2rem(32 * $multipleCount);
    width: calc(100% - px2rem(64 * $multipleCount));
    height: px2rem(88 * $multipleCount);
    margin-left: px2rem(32 * $multipleCount);
    background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018ca5aa-4322-8ace-61f9-1e31134817fe?x-oss-process=style/origin") no-repeat center center / cover;
  }
}
</style>
